import React from 'react';
import "../Theme.css";
import "./Intro_slide.css";
import TypeOut from '../../components/TypeOut/TypeOut';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';

const Intro_slide = () => {
    return (
        <Box className='slide' id='intro_slide'>
            <Grid className='slide_container' id='intro_slide_container'>
                <Grid item className='slide_content' id='intro-phrase'>
                    Hello, my name is
                </Grid>
                <Grid item className='slide_content' id='name'>
                    <TypeOut typeSpeed={65}>Khadija Sheikh</TypeOut>
                </Grid>
                <Grid item className='slide_content' id='introduction'>
                Im a 2nd year undergraduate student at Carleton University in the Bachelor of Commerce program. I am majoring in Marketing and enjoy taking on new challenges within graphics design and digital art to strengthen my skills for future marketing positions. I hope to build a career in marketing and aspire to become more creative throughout my journey as a marketing student.
                </Grid>
            </Grid>
        </Box>
    );
};

export default Intro_slide;